
import { Fancybox } from "@fancyapps/ui"

import "@fancyapps/ui/dist/carousel/carousel.css"

import { mapGetters } from "vuex"
export default {
  data() {
    return {
      initSlider: false
    };
  },
  props: {
    url: String,
    offer: Array,
    buttons: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters({
      settings: "settings/settings/settings"
    }),
    device() {
      return this.$device.isMobile ? this.$device.isMobile : false;
    }
  },

  mounted() {
    Fancybox.bind(`[data-fancybox="gallery-contacts"]`, {
      Hash: false,
      contentClick: "iterateZoom",
      Images: {
        protected: true
      }
    });

    // Initialise Carousel

    if (!this.$device.isMobile) {
      const sliderIndexSwiper = new swiper.default(".catalog__images .swiper", {
        modules: [swiper.Navigation, swiper.Pagination, swiper.Autoplay],
        loop: true,
        autoplayDisableOnInteraction: false,
        // autoHeight: true,
        watchSlidesProgress: true,
        scrollbar: false,
        speed: 330,

        on: {
          init: () => {
            this.initSlider = true;
          }
        },
        // pagination: {
        // 	el: '.swiper-pagination',
        // 	clickable: 'true',
        // 	type: 'bullets',
        // 	renderBullet: function (index, className) {
        // 		return '<span class="' + className + '">' + '<span class="swiper-pagination-bar"></span>' + '<span class="swiper-pagination-progress"></span>' + '</span>';
        // 	},
        // },
        slidesPerView: 1,
        spaceBetween: 16,
        navigation: {
          nextEl: ".swiper-button-next--contacts",
          prevEl: ".swiper-button-prev--contacts"
        }
        // breakpoints: {
        // 	// when window width is >= 320px
        // 	320: {
        // 		slidesPerView: 1,
        // 		spaceBetween: 10
        // 	},
        // 	// when window width is >= 480px
        // 	480: {
        // 		slidesPerView: 1,
        // 		spaceBetween: 16
        // 	},
        // 	// when window width is >= 640px
        // 	640: {
        // 		slidesPerView: 1,
        // 		spaceBetween: 16
        // 	}
        // },
      });

      // const mainCarousel = new Carousel(document.querySelector(`#mainCarousel-contacts`), {
      // 	Dots: {
      // 		dynamicFrom: 5
      // 	},
      // 	Navigation: {
      // 		container: "f-carousel__nav",
      // 		// button: "f-button",
      // 		nextTpl: `<div data-carousel-next="true" class="swiper-button__arrow swiper-button__arrow--right swiper-button__arrow--mini" >
      // 			<div class="arrow"></div>
      // 		</ div>`,
      // 		prevTpl: `<div data-carousel-prev="true" class="swiper-button__arrow swiper-button__arrow--left swiper-button__arrow--mini" >
      // 			<div class="arrow"></div>
      // 		</ div>`,
      // 	},
      // 	on:{
      // 		init:()=>{
      // 			this.initSlider = true
      // 		}
      // 	}

      // });

      // Thumbnails
      // const thumbCarousel = new Carousel(
      // 	document.querySelector(`#thumbCarousel${this.-contacts}`),
      // 	{
      // 		Sync: {
      // 			target: mainCarousel,
      // 			friction: 0
      // 		},
      // 		Dots: false,
      // 		Navigation: false,
      // 		center: true,
      // 		slidesPerPage: 1,
      // 		infinite: true
      // 	}
      // );
    }
  }
};
